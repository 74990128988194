import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import ImageFullWidth from '../components/ImageFullWidth'
import Wysiwyg from '../components/Wysiwyg'
import ArticleHeading from '../components/ArticleHeading'

export const query = graphql`
  query TextQuery($lang: String, $uid: String!) {
    prismic {
      data: allTexts(uid: $uid, lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            title
            body
            photo
            # photoSharp {
            #   childImageSharp {
            #     fluid(maxWidth: 1500) {
            #       ...GatsbyImageSharpFluid_withWebp_noBase64
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`

export default (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')

  if (!data) {
    return null
  }

  return (
    <>
      <ArticleHeading {...data} />
      <ImageFullWidth
        image={data.photo}
        imageSharp={data.photoSharp}
        columns="col-sm-12 col-lg-8 offset-lg-2"
      />

      <div className="row bigger">
        <div className="col-sm-12 col-md-10 col-lg-6 offset-md-1 offset-lg-3">
          <Wysiwyg content={data.body} />
        </div>
      </div>
    </>
  )
}
